import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Container, Typography } from "@material-ui/core";
import Section from "../components/section";
import ReactMarkdown from "react-markdown";
import SEO from "../components/seo";
import MainLayout from "../layouts/mainLayout";

interface LocationProps {
    location: Location;
}

const AboutPage: React.FC<LocationProps> = (props) => {
    const data = useStaticQuery(graphql`
    query {
        policy: policyJson(block: {eq: "privacy" }) {
            title
            content
        }
    }
    `);

    return (
        <MainLayout>
            <SEO title={data.policy.title} location={props.location} />
            <Section>
                <Container maxWidth="md">
                    <Typography variant="h2" component="h1" gutterBottom>{data.policy.title}</Typography>
                    <ReactMarkdown source={data.policy.content} />
                </Container>
            </Section>
        </MainLayout>
    )
}

export default AboutPage